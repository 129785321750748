@import '../../../../styles/variables';

.needhelp-subsection {
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid $greyBackground;
  font-size: $text-mega;
  font-weight: 700;
}

.needhelp-filter-subsection {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid $greyBackground;
  justify-content: space-between;

  .needhelp-filter-conainer {
    display: flex;
  }
  .needhelp-heading-right-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;
  }
}

.contact-info-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 20px 10px;
  font-size: $text-kilo;

  .contact-info-name-desc {
    display: flex;
    flex-direction: column;
    color: $subTextColor;
    text-transform: uppercase;
    font-size: $text-default;

    label {
      font-size: $text-kilo;
      font-weight: 400;
      text-transform: none;
      color: black;
    }
  }
}

.contact-info-edit__table {
  width: 100%;
  padding: 10px;
  font-size: $text-kilo;

  thead > tr {
    height: 50px;

    & > th {
      margin-left: 10px;
      text-align: left;
      border-bottom: 1px solid $greyBackground;
    }
  }

  tbody > tr {
    height: 50px;

    & > td {
      border-bottom: 1px solid $greyBackground;
      div {
        margin-right: 5px;
      }
    }
  }
}
