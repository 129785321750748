@import '../../../styles/_variables.scss';
// workflow-primary-btn styles

@mixin button {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: 36px;
  font-weight: 900;
  height: 6.5rem;
  justify-content: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  transition: 200ms;
  &__icon {
    margin-right: 1rem;
  }
  &:focus,
  &:hover {
    box-shadow: 0 0 30px 0 $primaryColor;
    outline: none;
  }

  @media screen and (max-width: 1367px) {
    height: 100px;
    font-weight: 700;
  }
}

.primary-btn {
  @include button;
  background: $primaryColor;
  border: none;
  color: white;
}
.primary-btn--disabled {
  @include button;
  background: #eee;
  border: none;
  color: black;
}

.primary-btn--ghost {
  @include button;
  background: transparent;
  border: solid 2px $primaryColor;
  color: $primaryColor;
  font-weight: 600;
  &:focus,
  &:hover {
    border: solid 2px white;
  }
}

.primary-btn--hyperlink {
  display: flex;
  background: none;
  border: none;
  align-items: center;
  color: $primaryColor;
  font-size: 35px;
  line-height: 125%;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
}

.workflow-primary-btn {
  @include button;
  background: $primaryColor;
  border: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0 0 30px 0 $primaryColor;
  color: white;
  &:focus,
  &:hover {
    outline: none;
  }
}

.primary-compact-btn {
  width: fit-content;
  padding: 25px;
}

.icon-btn {
  border: none;
  outline: none;
  background: none;
  padding: 10px 20px;
  position: relative;

  img {
    height: auto;
    width: 56px;
  }

  .icon-btn-badge {
    position: absolute;
    top: 56px;
    right: 15px;
    background: $badgeColor;
    height: 20px;
    width: 31px;
    border-radius: 40%;
    font-size: $text-default;
    padding-top: 3px;
  }
}
