@import '../../../../styles/_variables.scss';
.HelpDrawer__section {
  margin-top: 1.5rem;

  line-height: 1.5;
  border-top: solid 1px solid 1px rgba(255, 255, 255, 0.25);
  ul,
  ol {
    margin-left: 1rem;
    padding: 0;
  }
  li {
    margin-top: 0.5rem;
  }
  p {
    line-height: 1.5;
  }
}
.HelpDrawer__section__title {
  cursor: pointer;
  font-weight: 900;
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.5rem;
  &:before {
    content: url('./icon-chevron-down.svg');
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 0;
    top: 0.2rem;
  }
}
.HelpDrawer__section__answer {
  display: none;
  padding-left: 1.5rem;
}
.HelpDrawer__section--open {
  .HelpDrawer__section__title {
    &:before {
      transform: rotate(180deg);
    }
  }
  .HelpDrawer__section__answer {
    display: block;
    label {
      font-size: 1.1rem;
    }
    ol > li {
      font-size: 1.1rem;
    }
  }
}
.HelpDrawer__section__title {
  font-size: 16px;
  font-weight: 900;

  @media screen and (max-width: 1367px) {
    font-size: 1.25rem;
    font-weight: 700;
  }
}
