@import '../../../styles/variables';

$dash-filter-gap: 0.4rem;

.filter-tab__item {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  height: 50px;
  justify-content: center;
  margin-right: $dash-filter-gap;
  min-width: 75px;
  padding: 11px 20px;
  cursor: default;
}

.filter-tab__item-selected {
  background: $primaryColor;
  border-radius: 2px;
  color: white;
}
