/* Default Styles */

html,
body {
  height: 100vh;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
}
::-moz-selection {
}

a {
  text-decoration: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
#root {
  height: 100%;
}
