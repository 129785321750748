@import '../../../../styles/_variables.scss';

.receive-header__left {
  margin-top: 5px;
  width: 100% !important;
  border-bottom: 10px solid #f2f2f2;
  > img {
    height: 5.5rem;
  }
}

.receive_item_header_label {
  padding-top: 10px;
  text-align: center;
  > label {
    line-height: 3rem;
    font-size: 2rem;
  }
}

.receive_container {
  background: #f2f2f2;
  height: calc(100% - 100px);
  margin: 1rem;
  padding: 10px 5px;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  width: calc(100% - 2rem) !important;
  gap: 1rem;
  .receive-item_search {
    display: flex;
    flex-direction: column;
    background-color: white;
    height: auto;
    justify-content: flex-start;
    border: 2px solid #b0b0b0;
    row-gap: 50px;
    .receive-item_header {
      padding-left: 2rem;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}

.receive-border-active {
  background: $asurionPrimary;
  border-radius: 9999px;
  padding: 12px 31px 12px 31px;
}

.receive-addon_button {
  font-size: larger;
  height: $dash-search-height;
  margin-right: 5px;
  text-transform: capitalize;
  width: 111px;
  font-weight: 600;
}

.receive-search__input {
  font-size: 1.25rem;
  width: 95%;
  border: none;
  background: #f2f2f2;
  height: 57px;
  border-radius: 3px;
  padding: 1rem 1rem 1rem 4rem;
}

.receive-search {
  position: relative;
  margin-left: 2rem;
  flex-shrink: 1;
  // flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.receive-item_selection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;

  .receive-item_radio {
    box-sizing: border-box;
    height: 1.25rem;
    width: 5%;
    vertical-align: middle;
  }
  .receive-item_options {
    padding-left: 1rem;
    font-size: 1.25rem;
    font-weight: 400;
    vertical-align: middle;
  }
}

.tracking-info {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: flex-start;
  border: 2px solid #b0b0b0;
  row-gap: 28px;
  padding-top: 1rem;
  @media screen and (max-width: 1367px) {
    // height: calc(100% - 10rem);
  }
  .tracking-info_header {
    padding-left: 2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }
  .tracking-info_detail {
    width: 85%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    margin-left: 2rem;
    .tracking-info_element {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.395);
      padding-bottom: 0.75rem;
      .tracking-info_value {
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
      }
      .tracking-info_key {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
      }
    }
  }

  .tracking-num,
  .tracking-carrier {
    display: block;
  }
}

.tracking-info-active {
  background: $asurionPrimary;
  border-radius: 9999px;
  padding: 12px 31px 12px 31px;
}

.tracking-info_button {
  font-size: large;
  height: $dash-search-height;
  text-transform: capitalize;
  width: 38% !important;
  font-weight: 600;
}

.receive-unknownshipment-btn {
  margin: 20px auto;
  width: 135px;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: underline;
  text-underline-offset: 3px;

  &:hover {
    cursor: pointer;
  }
}

.error-container {
  box-sizing: border-box;
  align-items: center;
  padding: 12px;
  gap: 10px;
  width: 343px;
  height: auto;
  border: 1px solid #db1b1b;
  border-radius: 8px;
  background: #ffe9ed;
  margin-bottom: 28px;
  .error-container-icon {
    border: none;
    position: absolute;
    padding: 0;
    &:after {
      content: '';
      background: url('../../../../icons/icon-alert.svg');
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
  .error-container-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    align-items: center;
    color: #000000;
    margin: 0px 0px 0px 26px;
  }
  .error-container-link {
    margin: 14px 16px 4px 0px;
    text-align: right;
    color: #000000;
    text-decoration-line: underline;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
}
