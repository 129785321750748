// dash-header styles

.dash-header {
  display: flex;
  background: white;
  align-content: center;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 4px;
  min-height: 100px;
  order: 0;
  padding-right: 0;
  padding: 0 1rem;
  width: 100vw;
  @media screen and (max-width: 1367px) {
    width: 100%;
    padding-right: 0px;
  }
}

.dash-header__top {
  display: flex;
  justify-content: center;
  margin-top: 22px;
  width: 100%;
}

.dash-header__bottom {
  border-top: 2px solid #f2f2f2;
  font-size: 1.3rem;
  font-weight: 500;
  height: 70px;
  margin-left: -27px;
  margin-top: 12px;
  padding: 20px 0px;
  text-align: left;
  width: auto;

  .dash-header__bottom-title {
    display: flex;
    justify-content: space-between;
    font-size: 1.7rem;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 10px;
    width: 300px;
  }

  .dash-header__bottom-count {
    font-weight: 800;
  }
}

.dash-header__left {
  display: flex;
  flex-shrink: 0;
  margin-top: -8px;
  width: calc(300px + 1rem);
}

.dash-header__right {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  .dash-header__blank-space {
    width: 100%;
  }
}
