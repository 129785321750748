@import '../../../../styles/_variables.scss';

.returnsTitle {
    font-size: 2.5rem;
}

.returnSpor {
    background-color: #E6D3F6;
}

.restaging {
    background-color: #CCF6DD;
}

.returnSpor, .restaging {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 5px;
    display: inline-block;
}

.step-2-graphic-return {
 height: 100%;
}

.done-scanning-link {
    text-align: center;
    padding-top: 30px;
    font-size: 24px;
    color: $asurionPrimary;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

.warningTitle{
    color: #DB1B1B;
}

.stopSignDiv{
    text-align: center;
    margin-top: 25px;
    height: 192px;
    width: 50%;
}