// workflow-header-order-number styles
@import '../../../../styles/_variables.scss';

.workflow-order-number {
  align-items: center;
  background: white;
  border-radius: 3px;
  border: solid 3px black;
  color: black;
  display: inline-flex;
  min-height: 1.75em;
  justify-content: center;
  margin: 0 1rem;
  padding: 0.1em;
  position: relative;
  vertical-align: middle;
  min-width: 1.75em;
}

.skuOos-notes {
  text-align: left;
  font-size: 2.1rem;
}

.done-page--end {
  margin-top: 1rem;
}

.workflow-step__messaging.confirmation {
  padding: 0;
}

.workflow-step__messaging.confirmation .unabletolocate-done-wsn-list-p,
.workflow-step__messaging.confirmation .info-place-shipment-text {
  font-size: 2rem;
}

.workflow-step__messaging.confirmation .unabletolocate-done-wsn-list-p-bold {
  font-size: 2rem;
  font-weight: 800;
  margin-top: 0;
}

.workflow-step__messaging.confirmation .unabletolocate-undone-wsn-list-p-bold {
  font-size: 2rem;
  font-weight: 800;
  margin-top: 0;
  color: red;
}

.workflow-step__messaging.confirmation .info-jobs-left-text {
  font-size: 2rem;
  margin-top: 0;
}

.workflow-step__messaging-confirmation .info-jobs-left-text-bold {
  font-size: 2rem;
  font-weight: 800;
}

.unabletolocate-done-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unabletolocate-done-info-h1 {
  font-size: 3.5rem;
  margin-bottom: 0;
  margin-left: 15px;
  text-align: center;
}

.unabletolocate-done-wsn-list {
  background-color: #f0f0f5;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.info-content-wsn {
  padding: 50px;
  width: 100%;
  text-align: center;
}

.dashboard-link {
  text-align: center;
  padding-top: 30px;
  font-size: 24px;
  color: $asurionPrimary;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
