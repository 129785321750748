@import '../../../../styles/_variables.scss';

.step-1-graphic-return-abc {
    height: 200px;
    margin-left: 250px;
}

.return-dashboard-link {
    text-align: center;
    padding-top: 30px;
    font-size: 24px;
    color: $asurionPrimary;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}