@import '../../styles/_variables.scss';

.alert {
  align-items: center;
  border-bottom: solid 1px #e1e1e1;
  display: flex;
  font-size: $text-kilo;
  font-weight: 500;
  height: 4rem;
  justify-content: center;
  padding: 1rem 0;
  top: 100px;
  left: 0;
  width: 100%;
  z-index: 100;

  @media screen and (max-width: 1367px) {
    font-size: 2rem;
    height: 75px;
  }

  a {
    font-style: normal;
    color: inherit;
    text-decoration: underline;
  }

  .alert__btn {
    border: solid 2px;
    padding: 0.5rem 1rem;
    background: transparent;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    margin-left: 2rem;
    text-decoration: none;
    transition: 100ms;
    &:hover {
      color: white;
    }
    &__icon {
      display: inline-block;
      margin: 0 0.5rem;
      height: 10px;
      width: 10px;
    }
    @media screen and (max-width: 1367px) {
      font-size: 1.5rem;
    }
  }

  .alert__icon {
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    height: 30px;
    width: 30px;
    margin-right: 0.5rem;
    @media screen and (max-width: 1367px) {
      height: 50px;
      width: 50px;
    }
  }

  &--error {
    background: $errorBackgroundColor;
    color: $errorTextColor;
    .alert__icon {
      background-image: url('./icon-error.svg');
    }
    .alert__btn {
      border-color: $errorTextColor;
      color: $errorTextColor;
      &:hover {
        background: $errorTextColor;
      }
    }
  }

  &--help {
    background: $helpBackgroundColor;
    color: $helpTextColor;
    .alert__icon {
      background-image: url('./icon-back.svg');
    }
    .alert__btn {
      border-color: $helpTextColor;
      color: $helpTextColor;
      &:hover {
        background: $helpTextColor;
      }
    }
  }

  &--info {
    background: $infoBackgroundColor;
    color: $infoTextColor;
    .alert__icon {
      background-image: url('./icon-info--white.svg');
    }
    .alert__btn {
      color: $helpTextColor;
      border-color: $infoTextColor;
      &:hover {
        background: $infoTextColor;
      }
    }
  }

  &--success {
    background: $successBackgroundColor;
    color: $successTextColor;
    .alert__icon {
      background-image: url('./icon-success--white.svg');
    }
    .alert__btn {
      border-color: $successTextColor;
      color: $successTextColor;
      &:hover {
        background: $successTextColor;
      }
    }
  }

  &--warning {
    background: $warningBackgroundColor;
    color: $warningTextColor;
    .alert__icon {
      background-image: url('./icon-warning.svg');
    }
    .alert__btn {
      border-color: $warningTextColor;
      &:hover {
        background: $warningTextColor;
      }
    }
  }
}

.alert--squeezed {
  width: calc(100% - 350px);
  transform: translateX(350px);
}

.alert-appear {
  opacity: 0.01;
  height: 0px;
  * {
    position: absolute;
  }
}

.alert-appear.alert-appear-active {
  height: 100px;
  opacity: 1;
  transition: height 0.3s ease;
  * {
    position: relative;
    transition: position 0ms 300ms;
  }
}

// .alert-enter {
// }

// .alert-enter.alert-enter-active {
// }

// .alert-leave {
// }

// .alert-leave.alert-leave-active {
// }

.alert__btnlbl {
  border: none;
  background-color: #c4ddff;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0.25rem;
  padding: 0;
  text-decoration: underline;
  &:hover {
    font-weight: 700;
  }
  @media screen and (max-width: 1367px) {
    font-size: 2rem;
  }
}
