// asurion-loader styles
/*======================================
=            LOADERS            =
======================================*/

.loader {
  right: 0;
  margin: auto;
  position: absolute;
  left: 0;
  top: 30%;
}

.loader-screen {
  background: black;
  display: flex;
  z-index: 999;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}

.loader--relative {
  position: relative;
  left: auto;
  right: autol;
}

.loader,
.adg-loader-overlay {
  z-index: -9999;
  opacity: 0;
  transition: opacity 200ms;
}

.loader.active {
  z-index: 9999;
  opacity: 1;
}

.loader-overlay.active {
  z-index: 999;
  opacity: 1;
}

.loader-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(7, 29, 43, 0.65);
  z-index: 999;
}

.loader-wrap {
  position: relative;
  border: solid 1px rgba(255, 255, 255, 0.13);
  height: 300px;
  width: 100%;
  z-index: 9999;
}

.show-content {
  display: block;
}

.hide-content {
  display: none;
}

/*========================================
  =            ASURION LOADER A            =
  ========================================*/

.adg-loader {
  font-size: 10px;
  width: 10em;
  height: 10em;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: contain;
    background-repeat: no-repeat;
  }
  span {
    position: absolute;
    width: 10em;
    height: 10em;
    background-size: contain;
    background-repeat: no-repeat;
  }
  span:nth-child(1) {
    top: -7em;
    left: 2.5em;
    opacity: 0; // animation-delay:500ms;
  }
  span:nth-child(2) {
    left: 2em;
    top: 6.5em;
    opacity: 0;
    animation-delay: 250ms;
  }
  span:nth-child(3) {
    left: -8em;
    opacity: 0;
    animation-delay: 500ms;
  }
  p {
    font-weight: 600;
    color: white;
    position: absolute;
    top: 120%;
    font-size: 2em;
  }
}

.adg-loader--light {
  &:after {
    background-image: url(./images/adg-loader-center--white.svg);
    opacity: 0.35;
  }
  span {
    -webkit-animation: asurion--light 3s infinite ease;
    animation: asurion--light 3s infinite ease;
  }
  span:nth-child(1) {
    background-image: url(./images/adg-loader-top--white.svg);
  }
  span:nth-child(2) {
    background-image: url(./images/adg-loader-right--white.svg);
  }
  span:nth-child(3) {
    background-image: url(./images/adg-loader-left--white.svg);
  }
}

@keyframes asurion--light {
  0% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    opacity: 0;
  }
  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.35;
  }
  90% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    opacity: 0;
  }
}

@keyframes fillLeft {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.waiting-text {
  opacity: 0;
  transition: all 500ms;
  -webkit-transition: all 500ms;
}

.waiting-text.active {
  opacity: 1;
}
