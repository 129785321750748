.dash-table_search-string {
  background-color: #fff4bb;
  height: min-content;
}

.dash-table-search {
  display: flex;
  flex-direction: row;
  white-space: pre-wrap;
}
