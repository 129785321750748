// Default Styles

html,
body {
  height: 100vh;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
}
::-moz-selection {
}

a {
  text-decoration: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.apexcharts-datalabels-group {
  transform: translateY(20px);
}

.apexcharts-datalabel-label {
  font-size: 1.75rem !important;
  font-weight: 700;
  transform: translateY(40px);
}

.apexcharts-datalabel-value {
  font-size: 5.5rem;
  font-weight: 700;
  transform: translateY(-40px);
}

.apexcharts-legend-series {
  @media screen and (max-width: 1281px) {
    padding-left: 14px;
    margin-right: -10px !important;
  }
}

.apexcharts-legend-text {
  font-size: 1.5rem !important;
  padding-left: 5px;
  @media screen and (max-width: 1281px) {
    padding-left: 4px;
  }
}

.apexcharts-inner {
  @media screen and (max-width: 1281px) {
    transform: translateX(55px);
  }
}
