@import '../../../../styles/_variables.scss';

.receive-table_container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1367px) {
        height: calc(100% - 10rem);
    }

    .receive-table_label {
        background: white;
        height: 4rem;
        padding: 1rem 0 0 1rem;

        .label-heading {
            font-weight: 400;
            font-size: 25px;
            color: #000000;
        }
    }

    .receive-table_detail {
        margin-left: 0rem !important;
        width: 100%;
    }

    .receive-table-button_container {
        display: flex;
        flex-direction: row;
        gap: 5rem;
        background: white;
        padding: 3rem 0 2rem 2em;

        .receive-table-button_1 {
            @media screen and (max-width: 1367px) {
                // width: calc(100% - 10%);
            }
        }

        .receive-table-button_updateInv {
            background: $asurionPrimary;
            border-radius: 9999px;
            padding: 12px 31px 12px 31px;
            font-size: larger;
            height: $dash-search-height;
            margin-right: 5px;
            text-transform: capitalize;
            max-width: 100%;
            font-weight: 600;
        }
        .receive-table-button_updateInv-disabled {
            background: #FFFFFF;
            color: #A5AAAF;
            border-radius: 9999px;
            border: 1px solid #A5AAAF;
            padding: 12px 31px 12px 31px;
            font-size: larger;
            height: $dash-search-height;
            margin-right: 5px;
            text-transform: capitalize;
            max-width: 100%;
            font-weight: 600;
        }
        .receive-table-button_incorrectPart {
            background: $asurionPrimary;
            border-radius: 9999px;
            padding: 12px 31px 12px 31px;
            font-size: larger;
            height: $dash-search-height;
            margin-right: 5px;
            text-transform: capitalize;
            max-width: 100%;
            font-weight: 600;
        }
        .receive-table-button_printWsn {
            background: $asurionPrimary;
            border-radius: 9999px;
            padding: 12px 31px 12px 31px;
            font-size: larger;
            height: $dash-search-height;
            margin-right: 5px;
            text-transform: capitalize;
            max-width: 100%;
            font-weight: 600;
        }
        .receive-table-button_printWsn-disabled {
            background: #FFFFFF;
            color: #A5AAAF;
            border-radius: 9999px;
            border: 1px solid #A5AAAF;
            padding: 12px 31px 12px 31px;
            font-size: larger;
            height: $dash-search-height;
            margin-right: 5px;
            text-transform: capitalize;
            max-width: 100%;
            font-weight: 600;
        }
    }

    .received-column-input {
        height: 50px;
        width: 50px;
        border: 1px solid #A5AAAF;
        border-radius: 5px;
        padding-left: 5px;
    }

    .asurionBarcode-column-input {
        height: 50px;
        width: 200px;
        border: 1px solid #A5AAAF;
        border-radius: 5px;
        padding-left: 5px;
    }

    .receiving-error-msg {
        color: red;
        padding: 10px;
        font-size: 24px;
    }

    .error-msg-extra-padding {
        padding-top: 85px;
    }

    .tablecell-wrapper {
        display: flex;
        height: 50px;
        margin: auto;
        align-items: center;
    }

    .tablecell-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .tablecell-value-gray {
        color: #6E767D;
    }

    .asurionBarcode-column-checkmark {
        display: block;
        position: relative;
        top: -33px;
        left: 168px;
    }
}