// row styles

.pp-row {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  // margin-top:1rem;
  width: 100%;
  @include respond-to(small) {
    flex-direction: column;
  }
}
.pp-row--zero-margin {
  margin-top: 0;
}

.pp-row--reverse {
  @include respond-to(small) {
    flex-direction: row-reverse;
  }
}
