// workflow-header-order-number styles
@import '../../../../styles/_variables.scss';

.workflow-order-number {
    align-items: center;
    background: white;
    border-radius: 3px;
    border: solid 3px black;
    color: black;
    display: inline-flex;
    min-height: 1.75em;
    justify-content: center;
    margin: 0 1rem;
    padding: 0.1em;
    position: relative;
    vertical-align: middle;
    min-width: 1.75em;
}

.skuOos-notes {
    text-align: left;
    font-size: 2.1rem;
}

.done-page--end {
    margin-top: 1rem;
}

.workflow-step__messaging.restaging-confirmation {
    padding: 0;
}


.restaging-done-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.restaging-done-info-h1 {
    font-size: 3.5rem;
    margin-bottom: 0;
    margin-left: 15px;
    text-align: center;
}

.restaging-completed-main {
    height: 120px;
    width: 100%;
    display: flex;
    background-color: #F0F0F5;
    align-items: center;
    justify-content: center;
}
.place-shipment-text {
    display:flex;
    padding-top: 50px;
    font-size: 1.5rem;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.restage-text {
    font-size: 1.5rem;
}
.info-content-wsn {
    padding: 50px;
    width: 100%;
    text-align: center;
}

.dashboard-link {
    text-align: center;
    padding-top: 30px;
    font-size: 24px;
    color: $asurionPrimary;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}
