@import '../../../../styles/_variables.scss';

.return-completed {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    text-align: center;
    width: 100%;
}

.return-completed-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 30px;
}

.return-completed-text {
    display: inline-block;
    font-weight: bold;
    font-size: 3rem;
    margin-left: 30px;
}

.return-completed-main {
    height: 120px;
    width: 100%;
    display: flex;
    background-color: #F0F0F5;
    align-items: center;
    justify-content: center;
}

.scanned-text {
    font-size: 1.5rem;
}

.place-parts-text {
    display: inline-block;
    padding-top: 50px;
    font-size: 1.5rem;
}

.go-back-link {
    text-align: center;
    padding-top: 30px;
    font-size: 24px;
    color: $asurionPrimary;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}
