// workflow styles
.workflow {
  background: rgba(240, 240, 245, 0.95);
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: opacity 100ms;
  @media screen and (max-width: 1367px) {
    zoom: 85%;
  }
  @media screen and (max-width: 1281px) {
    zoom: 71%;
  }
}

.workflow--squeezed {
  width: calc(100% - 350px);
  transform: translateX(350px);
}
