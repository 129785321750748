@import '../../../styles/_variables.scss';

.dash-timer {
  display: flex;
  align-items: center;
  cursor: context-menu;
  flex-shrink: 0;
}

.dash-timer__icon {
  margin-right: 0.5rem;
}

.dash-timer__text {
  font-size: $text-kilo;
  margin-right: 0.5rem;
  color: #414141;
  @media screen and (max-width: 1367px) {
    font-size: 1.5rem;
  }
}

.dash-timer__time {
  font-size: $text-giga;
  font-weight: 800;
  margin-right: 2rem;
  width: 82px;
}

.dash-timer__info {
  font-size: $text-giga;
  font-weight: 800;
  margin-right: 3rem;
}
