@import '../../../styles/_variables.scss';
.HelpDrawer {
  background: #555b63;
  box-shadow: inset 0px 0px 30px 0px rgba(0, 0, 0, 0.35);
  color: white;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 350px;
  z-index: 101;
  @media print {
    display: none;
  }
  @media screen and (max-width: 1367px) {
    height: 134vh;
  }
}
.HelpDrawer__back-button {
  position: relative;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  &:after {
    background: #353940;
    border-radius: 100%;
    content: '';
    height: 3rem;
    left: 0;
    right: 0;
    position: absolute;
    transition: 200ms;
    transform: scale(0);
    top: 0;
    width: 3rem;
    z-index: 0;
  }
  &:hover {
    &:after {
      transform: scale(1);
    }
  }
}
.HelpDrawer__back-button__icon {
  z-index: 1;
  position: relative;
}

.HelpDrawer__header {
  align-items: center;
  background: #444950;
  display: flex;
  height: 5.5rem;
  padding: 1rem;
  @media screen and (max-width: 1367px) {
    height: 4rem;
  }
}
.HelpDrawer__main {
  padding: 1rem;
}
.HelpDrawer__footer {
  padding: 0.7rem 1rem;
  font-weight: 500;
  border-top: solid 1px rgba(255, 255, 255, 0.24);
  position: absolute;
  bottom: 0;

  p {
    line-height: 1.5;
    font-size: $text-default;

    @media screen and (max-width: 1367px) {
      font-size: 1.1rem;
    }
  }
}

.HelpDrawer__footer__title {
  font-size: $text-kilo;
  font-weight: 700;
}
.HelpDrawer__title {
  font-size: $text-giga;
  margin: 0;
  padding: 0;
}
