.AppDashboard {
  height: 100%;
  overflow: auto;
}

@media screen and (max-width: 1367px) {
  body {
    zoom: 0.75; /* Other non-webkit browsers */
    zoom: 75%; /* Webkit browsers */
    height: 97.7%;
  }
}

.hyperlink-text {
  text-decoration: underline;
  font-weight: 600;
}
