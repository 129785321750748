@import '../../../../styles/_variables.scss';

@mixin button {
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  font-size: $text-tera;
  font-weight: 900;
  height: 100px;
  justify-content: center;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  transition: 200ms;
  &__icon {
    margin-right: 1rem;
  }
  &:hover {
  }
  &:focus {
    box-shadow: 0 0 30px 0 $asurionPrimary;
    outline: none;
  }
}

.workflow-primary-btn {
  @include button;
  background: $asurionPrimary;
  border: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  color: white;
  @media screen and (max-width: 1367px) {
    font-weight: 700;
  }
}
