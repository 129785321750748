// abcprint-label styles
@import '../../styles/_variables.scss';

@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended|Libre+Barcode+39+Text');
.abc-label--print {
  display: none;
}

@media print {
  .abc-label--print {
    display: block;
    margin-bottom: 130px;
    height: 120px;
  }

}

.abcBarcodeText {
  font-size: 16px;
  display: block;
  text-align: center;
  margin-left: 10px;
}
