@import '../../../../styles/_variables.scss';
@import '../../../../styles/_mixins/_responsive-mixins.scss';

.wsn-title {
    display: inline-block;
    width: 55%;
}

.wsn-count {
    color: $asurionPrimary;
}

.barcode-example {
    width: 350px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #E6E6EB;
}

.barcode-example-text {
    margin-bottom: 10px;
    font-weight: 700;
}

.wsnListContainer {
    height: 390px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 28px;
    overflow: scroll;
    margin-top: 6px;
}

.wsnContainer {
    position: relative;
    width: 60%;
    margin: 10px 86px;
}

.wsnLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    padding: 10px 60px;
    border-radius: 8px;
    border: 1px solid #000000;

    @media (max-width: $screen-sm) {
        font-size: 2rem;
        padding: 15px 40px;
    }
}

.wsnMonth {
    margin-right: 5px;
}

.wsnSerial {
    display: inline;
    color: #FFFFFF;
    background-color: #000000;
    padding: 5px;
}

.kittedStatus {
    opacity: .4;
}

.checkmark-icon {
    width: 30px;
    position: absolute;
    top: -12px;
    left: -13px;
    z-index: 1;
}

.locate-shipment-link {
    text-align: center;
    padding-top: 30px;
    font-size: 24px;
    color: $asurionPrimary;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}
