// workflow-help-button styles
@import '../../../../styles/_variables.scss';

.workflow-help-button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 3px;
  color: $asurionPrimary;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 100px;
  justify-content: center;
  margin: auto;
  vertical-align: middle;
  text-decoration: underline;
  transition: 200ms;
  outline: none;
  &__icon {
    width: 1.5rem;
    margin-right: 0.25rem;
  }
  @media screen and (max-width: 1367px) {
    font-size: 1.6rem;
    font-weight: 600;
  }
}
