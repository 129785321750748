.App {
  height: 100%;
  overflow: auto;
}

.dashboard-selection-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #E4E4E4;

  .dashboard-selection-content_header {
    height: 6rem;
    text-align: center;
    position: relative;
    padding-top: 1rem;
    background-color: white;

    .dashboard-selection-header_label {
      font-family: "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-style: normal;
      font-size: 60px;
      line-height: 100%;
      color: #000000;
      font-weight: lighter;
    }
  }
}

@media screen and (max-width: 1367px) {
  body {
    zoom: 0.75;
    /* Other non-webkit browsers */
    zoom: 75%;
    /* Webkit browsers */
    height: 97.7%;
  }
}

.grid-app-container_main {
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 4rem;
  margin: 1.25rem 2rem 0px 2rem;
  padding: 10rem 0px 20rem 0px;
  background: white;
  height: calc(100% - 10rem);
  justify-content: center;

  .grid-app-container_main_label {
    text-align: start;
    height: 5rem;

    .grid-app-container_main_label_heading {
      font-weight: normal;
      font-size: 30px;
      text-align: center;
      color: #000000;
      font-family: "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      line-height: 5rem;
    }
  }

  .grid-app-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 15rem;
    height: 30rem;
    justify-content: center;

    .grid-app-container-body {
      display: grid;
      grid-template-rows: auto auto auto;
      height: 30rem;
      grid-gap: 1rem;

      .grid-app-container-body_label {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        color: #000000;
        font-family: "Segoe UI", "Roboto", "Helvetica", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      }

      .grid-app-container-body_label_footer {
        font-weight: lighter !important;
      }
    }

    .dashboard_selection_button {
      background: white;

      .dashboard_image_selection {
        height: 20rem;
        width: 20rem;
      }
    }
  }
}

.section-heading {
  justify-content: center !important;
}

.hyperlink-text {
  text-decoration: underline;
  font-weight: 600;
}
