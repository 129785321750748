@import './../../../../styles/variables';

.section-content {
  height: 100%;
  width: 100%;

  .section-header {
    display: flex;
    padding: 10px;
    align-content: center;
    justify-content: space-between;
    height: 65px;
    border-bottom: 2px solid $greyBackground;

    label.expert-label {
      font-size: $text-giga;
      font-weight: 700;
      flex: 2;
      padding-left: 1em !important;
    }
  }
}

.expert-list-container {
  padding: 10px;
  height: fit-content;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: auto auto auto auto;
}

.gridListTileCustom {
  width: 20% !important;
}

.listStyle {
  font-style: normal;
  font-size: $text-mega;
  line-height: 40px;
  align-items: center;
  margin-top: unset;
  text-transform: capitalize;
}
