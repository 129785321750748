@import '../../../../styles/variables';

.hotnews-subsection {
  height: 70px;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border-bottom: 1px solid $greyBackground;
  font-size: $text-mega;
  font-weight: 700;
}

.error-message {
  height: 40px;
  font-size: 1.2rem;
  background: $errorBackgroundColor;
  margin: -10px -10px 10px -10px;
  padding: 10px;
  padding-left: 20px;
}

.new-content__container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid $greyBackground;

  .news-heading__container {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;

    .news-heading__badge {
      height: 16px;
      width: 10px;
    }

    .news-heading__badge-new {
      width: 16px;
      border-radius: 50%;
      background: $badgeColor;
      margin: 5px 10px;
    }

    .news-heading__title {
      display: flex;
      flex-direction: column;
      font-size: $text-kilo;
      color: $subTextColor;

      label {
        font-size: $text-mega;
        font-weight: 700;
        color: black;
      }
    }
  }

  .news-body__container {
    display: flex;
    flex-direction: row;
    font-size: $text-kilo;
    padding: 10px 20px;

    .news-body__content {
      flex: 2;
      padding-right: 10px;
      white-space: pre-wrap;
    }

    .news-attachment__container {
      height: 100%;
      width: 20%;
      line-height: $text-kilo;
      border: 1px $primaryColor solid;
      padding: 10px;

      .news-attachment__heading {
        display: flex;
        flex-direction: row;

        // img {
        // }
        h1 {
          padding-top: 16px;
          font-size: $text-kilo;
        }
      }

      .news-attachment__link {
        text-decoration: underline;
        color: $primaryColor;
        padding: 1rem 0;
        font-weight: 700;
      }
    }
  }
}
