.difference-span-container {
  text-align: left;
  padding-bottom: 25px;
}

.difference-span-div {
  width: 800px;
  height: 80px;
  left: 402px;
  top: 470px;
  font-style: normal;
  font-weight: 900;
  font-size: 1.8rem;
  line-height: 73px;
  padding-left: 25px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: 10px;
}

.error-ds {
  background: #e91e4d;
  color: #000000;
}

.info-ds {
  background: #103562;
  color: #ffffff;
}

.underline {
  text-decoration: underline;
}
