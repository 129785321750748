// workflow-primary-input styles
@import '../../../../styles/_variables.scss';

.workflow-primary-field {
}

.workflow-primary-field__input-group {
  background: white;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  display: flex;
  font-size: $text-giga;
  height: 6rem;
  position: relative;
  transition: 100ms;
  width: 100%;

  @media screen and (max-width: 1367px) {
    height: 6.5rem;
  }

  input {
    border-top: solid 1px #e1e1e1;
    &:focus {
      border-top: solid 1px transparent;
      box-shadow: 0 0 2rem 0 $asurionPrimary;
    }
  }
}
.workflow-primary-field--valid {
  .workflow-primary-field__input-group {
    background: $successBackgroundColor;
    box-shadow: none;
    color: $successTextColor;
  }
  .workflow-primary-field__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &:before {
      background: $successBackgroundColor;
    }
    &:after {
      content: '';
      background: url('./icon-success--white.svg');
      background-size: 100%;
      height: 3rem;
      opacity: 1;
      transform: translateY(0);
      width: 3rem;
      z-index: 9;
    }
  }
  .workflow-primary-field__btn__icon {
    display: none;
  }
  .workflow-primary-field__input {
    &::placeholder {
      color: white;
      font-weight: 600;
    }
  }
}

.workflow-primary-field--error {
  .workflow-primary-field__input-group {
    background: $errorBackgroundColor;
    box-shadow: none;
    color: $errorTextColor;
    input {
      &:focus {
        box-shadow: rgba(233, 30, 77, 0.24);
      }
    }
  }
  .workflow-primary-field__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    &:before {
      background: $errorBackgroundColor;
    }
    &:after {
      content: '';
      background: url('./icon-error.svg');
      background-size: 100%;
      height: 3rem;
      opacity: 1;
      transform: translateY(0px);
      width: 3rem;
      z-index: 9;
    }
  }
  .workflow-primary-field__btn__icon {
    display: none;
  }
  .workflow-primary-field__input {
    &::placeholder {
      color: white;
      font-weight: 600;
    }
  }
  .workflow-primary-field__errorMsg {
    visibility: visible;
    display: block;
  }
}
.workflow-primary-field__input {
  background: transparent;
  border: none;
  color: inherit;
  font-size: $text-tera;
  padding: 0;
  padding-right: 2rem;
  padding-left: 8rem;
  // text-align: center;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #c1c1c1;
    font-weight: 300;
    font-size: $text-giga;
    letter-spacing: -1px;
    margin-bottom: -1rem;
    vertical-align: top;
  }
}
.workflow-primary-field__label {
  display: hidden;
}
.workflow-primary-field__btn {
  align-items: center;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 8rem;
  &:before {
    content: '';
    position: absolute;
    opacity: 0;
    transition: 100ms;
    height: 100%;
    width: 100%;
  }
  &:after {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 100ms, opacity 100ms;
  }
  &:focus {
    outline: none;
  }
}
.workflow-primary-field__errorMsg {
  display: none;
  visibility: hidden;
  position: relative;
  top: calc(100%);
  background: #a73235;
  width: 100%;
  color: white;
  justify-content: center;
  font-weight: 600;
  padding: 0.5rem;
  padding-left: 8rem;
}
.workflow-primary-field__errorLink {
  color: white;
  border: solid 2px white;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  margin-left: 1rem;
  transition: 100ms;
  &:hover {
    background: white;
    color: #a73235;
  }
}
