// workflow-header styles
.workflow-header-container {
  width: 100%;
}

.workflow-header {
  align-items: center;
  background: white;
  border-bottom: solid 1px #e1e1e1;
  display: flex;
  flex-grow: 0;
  height: 5.5rem;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: 1367px) {
    height: 7rem;
  }

  @media print {
    display: none;
  }
}

.workflow-header__nav {
  align-items: center;
  display: flex;
  left: 0;
  padding-left: 2rem;
  position: absolute;
}

// workflow-header-btn styles

.workflow-header-btn {
  position: relative;
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  &:after {
    background: #cfe4ff;
    border-radius: 100%;
    content: '';
    height: 3rem;
    left: 0;
    right: 0;
    position: absolute;
    transition: 200ms;
    transform: scale(0);
    top: 0;
    width: 3rem;
    z-index: 0;
  }
  &:hover {
    &:after {
      transform: scale(1);
    }
  }
}

.workflow-header-btn__icon {
  z-index: 1;
  position: relative;
}

.workflow-header-btn__text {
  font-weight: 600;
  a {
    margin-left: 0.5rem;
    text-decoration: underline;
    color: #4a90e2;
  }
}

.workflow-close-btn {
  margin-top: 2rem;
}
