// Helpers

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Text Color helpers

.color-white {
  color: white;
}

// Alignment
.align-right {
  align-self: flex-end;
}
.text-center {
  text-align: center;
}

// flex
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
