// receiving-wsn-print-label styles
@import '../../styles/_variables.scss';

@import url('https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended|Libre+Barcode+39+Text');
.wsn-receiving-label--print {
  display: none;
}

@media print {
  html,
  body,
  #root,
  .App,
  .receive_container {
    height: 100% !important;
    overflow: hidden;
  }

  .section-content,
  .receive-left-content,
  .receive-table_container,
  .receive-header__left,
  .receive_item_header_label {
    display: none;
  }

  body,
  html,
  p {
    margin: 0.1 !important;
    padding: 0.1 !important;
  }

  @page {
    margin: 0.1 !important;
    padding: 0.1 !important;
    size: 1.5in 4in;
  }

  .wsn-receiving-label--print {
    display: block;
    border-radius: 15px;
    border: solid 2px black;
    page-break-after: always;
    width: 320px;
    overflow: hidden;
    margin-top: -25px;
    margin-left: -85px;
    padding-right: 20px;
  }
}

.receiving-barcodeText {
  margin-top: 0;
  font-size: 8px;

  display: block;
  text-align: center;
}

.receiving-barcodeSerial {
  display: block;
  text-align: center;
  font-size: 52px;
  padding: 2px;
  margin: 2px;
}
