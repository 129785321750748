@import '../../../../styles/variables';

.menu-container {
  position: relative;
  margin-right: 15px;

  .menu-main {
    position: absolute;
    top: 70px;
    right: 15px;
    z-index: 10;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

    .menu-item {
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
      height: 70px;
      width: 350px;
      display: flex;
      background: white;
      align-items: center;
      justify-content: space-around;
      cursor: default;

      .menu-icon {
        margin: 15px;
        img {
          height: auto;
          width: 30px;
        }
      }

      .menu-name {
        flex: 2;
        text-align: left;
        color: $primaryColor;
        font-size: $text-mega;
        font-weight: 900;
        text-transform: uppercase;
      }

      .menu-badge {
        background: $badgeColor;
        color: black;
        border-radius: 50%;
        margin-right: 5px;
        width: 24px;
        height: 24px;
        padding: 2px 7px;
      }
    }
  }
}
