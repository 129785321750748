// dash-search styles
@import '../../../styles/_variables.scss';

$dash-search-height: 57px;

.dash-search {
  position: relative;
  max-width: 700px;
  min-width: 300px;
  flex-shrink: 1;
  margin-right: 2rem;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}

.dash-search__label {
  visibility: hidden;
  display: none;
}

.dash-search__input {
  font-size: $text-kilo;
  width: 100%;
  border: none;
  background: #f2f2f2;
  height: $dash-search-height;
  border-radius: 3px;
  padding: 1rem 1rem 1rem 4rem;
  @media screen and (max-width: 1367px) {
    font-size: 1.4rem;
  }
  &:focus {
    box-shadow: 0px 0px 20px #4a90e2;
    border: #4a90e2 1px solid;
  }
}

.dash-search__button {
  background: none;
  border: none;
  left: 0;
  position: absolute;
  top: 0;
  height: $dash-search-height;
  width: 3.5rem;
  padding: 0;
  display: inline-block;
  cursor: pointer;
  &:after {
    background-image: url('./icon-barcode.svg');
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: '';
  }
}

.search-addon__button {
  font-size: larger;
  height: $dash-search-height;
  margin-right: 5px;
  text-transform: capitalize;
  width: 160px;
}

.search-border-active {
  box-shadow: 0px 0px 20px #4a90e2;
  border: #4a90e2 1px solid;
}
