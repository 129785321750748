@import '../../../../styles/_variables.scss';

.receiving-unknown-shipment-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.unknown-shipment-header {
    padding-left: 18px;
    padding-right: 18px;
    display: flex;

    .title {
        font-size: 40px;
        font-weight: 500;
        margin-right: 45px;
    }
}

.unknown-shipment-body {
    display: flex;
    flex-direction: column;
    padding-left: 18px;
    padding-right: 18px;

    .unknown-shipment-description {
        display: flex;
        font-size: 24px;
    }

    .unknown-shipment-description-inventory {
        display: flex;
        font-size: 24px;
    }

    .unknown-shipment-inventory-items {
        max-height: 600px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .shipment-info, .inventory-info {
        display: flex;
        font-size: 28px;
        font-weight: 500;
        padding-top: 40px;
    }

    .inventory-info-item-container {
        .inv-container {
            display: flex;
            flex-wrap: wrap;
            .inv-container-item {
                padding: 5px 5px;
                .inv-container-item-width {
                    width: 100%;
                }
            }
        }
        .inv-container > div {
            flex-basis: 50%;
        }
    }

    .inventory-item-label-container {
        display: flex;
        font-size: 24px;
        font-weight: 700;
        margin-top: 35px;
        margin-bottom: 15px;
        justify-content: space-between;

        .inventory-item-remove {
            margin-left: 15px;

            &:hover {
                cursor: pointer;
                text-decoration: underline;
                color: red;
            }
        }
    }

    .shipment-servicetype,
    .shipment-tracking,
    .shipment-vendor,
    .shipment-received-date,
    .inventory-service-job-num,
    .inventory-order-num,
    .inventory-item-name,
    .inventory-item-sku,
    .inventory-quantity,
    .inventory-vendor-order-num,
    .inventory-item-distr-num {
        border: 1px solid #A5AAAF;
        border-radius: 8px;
        margin-top: 15px;
        padding: 15px;
    }

    .inventory-item-add-container {
        display: flex;
        margin-top: 15px;
    }

    .inventory-item-add {
        font-size: 24px;
        text-decoration: underline;
        line-height: 64px;
        color: $asurionPrimary;

        &:hover {
            cursor: pointer;
        }
    }
}

.unknown-shipment-close {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-top: 10px;
    position: absolute;
    top: 10px;
    right: 25px;

    &:hover {
        cursor: pointer;
    }
}

.unknown-shipment-checkmark {
    width: 75px;
    height: 75px;
}

.unknown-shipment-confirm-message {
    font-size: 40px;
    width: 475px;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    margin-left: 15px;
}

.unknown-shipment-footer {
    .btn-container {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-right: 10px;
        padding-bottom: 5px;
        display: flex;
        justify-content: end;
    }

    .cancel-btn {
        background: #FFFFFF;
        border-radius: 9999px;
        border-color: $asurionPrimary;
        padding: 12px 31px 12px 31px;
        font-size: 24px;
        height: $dash-search-height;
        margin-right: 5px;
        text-transform: capitalize;
        max-width: 100%;
        font-weight: 600;
        color: $asurionPrimary;

        &:hover {
            cursor: pointer;
        }
    }

    .next-btn {
        background: $asurionPrimary;
        color: #FFFFFF;
        border-radius: 9999px;
        border-color: $asurionPrimary;
        padding: 12px 31px 12px 31px;
        font-size: 24px;
        height: $dash-search-height;
        margin-right: 5px;
        text-transform: capitalize;
        max-width: 100%;
        font-weight: 600;

        &:hover {
            cursor: pointer;
        }
    }

    .next-btn-disabled {
        background: #E6E6EB;
        color: #A5AAAF;
        border-radius: 9999px;
        border: 1px solid #E6E6EB;
        padding: 12px 31px 12px 31px;
        font-size: 24px;
        height: $dash-search-height;
        margin-right: 5px;
        text-transform: capitalize;
        max-width: 100%;
        font-weight: 600;
    }
}

.top-border {
    border-top: 1px solid #E6E6EB;
}

.error-msg-container {
    color: red;
}
