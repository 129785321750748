@import '../../../../styles/_variables.scss';

.workflow-progress-bar__item {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 1rem;
  position: relative;
  // flex-grow: 1;
  text-align: center;
  width: 50%;
  z-index: 1;
  &:not(:last-child):after {
    background: url('./workflow-progress-bar-vector.svg');
    background-repeat: no-repeat;
    background-position: right center;
    content: '';
    height: 100%;
    position: absolute;
    right: 0rem;
    width: 25px;
    z-index: 2;
  }
}
.workflow-progress-bar__item--current {
  .workflow-progress-bar__item__text {
    color: black;
  }
}
.workflow-progress-bar__item__text {
  color: #414141;
  font-size: $text-mega;
  font-weight: 700;
}
