@import '../../../styles/_variables.scss';

.dash-alert {
  align-content: center;
  background: #FF5070;
  display: flex;
  flex-direction: row;
  height: 75px;
  justify-content: center;
  width: 100%;
}

.dash-alert-hide {
  display: none;
}

.dash-alert-icon {
  margin: 19px;
  margin-right: 15px;
  &:after {
    background-image: url('./icon-alert.svg');
    background-size: 34px 34px;
    content: '';
    display: inline-block;
    height: 34px;
    width: 34px;
  }
}

.dash-alert-message {
  align-items: center;
  color: #000000;
  display: flex;
  font-size: $text-mega;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
  text-align: center;
}

.dash-alert-button {
  align-items: center;
  border-radius: 100px;
  border: 2px solid #000000;
  box-sizing: border-box;
  color: #000000;
  background-color: #FF5070;
  display: flex;
  font-size: $text-mega;
  font-style: normal;
  font-weight: normal;
  height: 55px;
  height: fit-content;
  line-height: 100%;
  margin: 10px;
  padding: 12px;
  padding-left: 20px;
  padding-right: 22px;
  text-align: center;
}
