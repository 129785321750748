// modal styles
@import '../../styles/_variables.scss';

.small-modal {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 200;

  &:before {
    content: '';
    background: #000000;
    height: 100vh;
    position: fixed;
    transition: opacity 400ms;
    width: 100vw;
    z-index: -1;
    opacity: .5;

    @media screen and (max-width: 1367px) {
      height: 100%;
      width: 100%;
      display: flex;
      text-align: center;
      align-content: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 1367px) {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    align-content: center;
    justify-content: center;
  }
}

.small-modal-in {
  align-content: center;
  align-items: center;
  background: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  justify-content: center;
  margin: 2rem;
  position: relative;
  top: -3rem;
  transform: translateY(50px);
  transition: opacity 200ms 200ms, transform 200ms 200ms;
  width: calc(100vw - 4rem);

  @media screen and (max-width: 1367px) {
    height: 298px;
    zoom: 88%;
    width: 560px;
    margin: 29.5rem 0.8rem;
  }
}

.small-modal-in-override {
  @media screen and (max-width: 1367px) {
    margin: auto 0;
    height: auto;
    width: auto;
    justify-content: flex-start;
  }
}

.modal-close-btn {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 3rem;
  top: 3rem;

  img {
    height: 3rem;
    width: 3rem;
  }
}

.modal-main {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 960px;
}

.modal-title {
  // font-size: $text-peta;
  font-size: 55px;
  font-weight: 800;
}

.modal-message {
  font-size: $text-mega;
  font-size: 24px;
  & p + p {
    margin-top: 0;
  }
  @media screen and (max-width: 1367px) {
    font-size: 1.75rem;
  }
  ol {
    counter-reset: item;
    margin: 15px 60px;
    text-align: initial;
    & > li {
      list-style-type: none;
      margin: 0;
      padding: 0 0 0 2rem;
      text-indent: -2rem;
      counter-increment: item;
      font-size: 1.85rem;
      &:before {
        display: inline-block;
        content: counter(item) '.';
        font-weight: bold;
        padding-right: 0.9rem;
        text-align: right;
      }
    }
  }
}

.modal-buttons {
  margin-top: 5rem;
  width: 100%;

  *:not(:first-child) {
    margin-top: 1rem;
  }
}

.modal-buttons-override {
  margin-top: 0;
}

.modal-custom-title {
  display: flex;
  flex-direction: row;
}

.modal-loader {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  zoom: 200%;

  & div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #4a90e2;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: modal-loader1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 6px;
      animation: modal-loader2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 26px;
      animation: modal-loader2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 45px;
      animation: modal-loader3 0.6s infinite;
    }
  }
}

@keyframes modal-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes modal-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes modal-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

// Client Selection

.client-selection--container {
  border-radius: 2px;
  border: 2px solid #d5d6da;
  border-top: 10.9px solid;
  border-bottom: none;
  box-sizing: border-box;
  font-size: 2.5rem;
  height: 383.89px;
  line-height: 47px;
  margin-right: 20px;
  width: 350px;

  .client-selection--clientname {
    font-size: 3rem;
    line-height: 59px;
    margin-top: 25px;
  }

  .client-selection--ordercount {
    font-size: 8rem;
    line-height: 120px;
  }

  .client-selection--text {
    margin-bottom: 30px;
  }
}

.client-selection--end {
  margin-top: -3rem;
}
