@import '../../../../styles/_variables.scss';

.receiving-complete-container {
    display: flex;
    width: 560px;
    padding-top: 50px;
    padding-left: 25px;
    padding-right: 25px;
}

.receiving-checkmark {
    align-self: baseline;
}

.receiving-complete-text {
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
    width: 335px;
    text-align: left;
    padding-left: 15px;
}

.receiving-close {
    align-self: baseline;
    padding-left: 75px;

    &:hover {
        cursor: pointer;
    }
}

.receiving-complete-next-btn {
    background-color: $asurionPrimary;
    border: 1px solid $asurionPrimary;
    border-radius: 9999px;
    font-family: inherit;
    font-size: 18px;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 12px 20px;
    color: #F2F2F2;
    width: 368px;
    height: 48px;
    margin-top: 25px;

    &:hover {
        background-color: #F2F2F2;
        color: $asurionPrimary;
        cursor: pointer;
    }
}

.receiving-complete-stay-btn {
    margin: 20px auto;
    width: 135px;
    font-size: 18px;
    line-height:18px;
    color: $asurionPrimary;
    text-decoration: underline;
    text-underline-offset: 3px;

    &:hover {
        cursor: pointer;
    }
}