.date-lbl {
  color: #000000 !important;
  background-color: #ffffff !important;
  -webkit-print-color-adjust: exact;
  border: 2px solid;
  // width: calc(var(--variable-width) + 20px); commeneted as of now to take variable width on modal
  height: 80px;

  .order-id {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
    font-weight: 900;
    text-align: center;
    font-size: 50px;
    font-family: 'SFProDisplay-Black';
    padding-top: 5px;
  }

  .order-id__selected {
    background-color: #878f6c;
    margin: 0px 7px;
    height: 54px;
    margin-bottom: 8px;
  }

  .packingLabelText--bold {
    font-size: 1.1rem;
    margin-top: -10px;
    margin-left: -52px;
  }
}

.date-lbl--inverted {
  background: #214063 !important;
  border: 2px solid #103562 !important;
  color: #ffffff !important;
  box-sizing: border-box;
  border-radius: 2px;
}
