.dashboard {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100vw;
  background: #f2f2f2;
  align-content: flex-start;

  @media print {
    display: none;
  }
  @media screen and (max-width: 1367px) {
    width: 100%;
  }
}
.dashboard--squeezed {
  width: calc(100% - 350px);
  transform: translateX(350px);
}
