// dash-side styles

.dash-side {
  background: white;
  height: calc(100% - 100px);
  margin-right: 4px;
  order: 1;
  overflow-y: auto;
  width: 300px;
  position: relative;
}
