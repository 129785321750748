// workflow-header-order-number styles
@import '../../../../styles/_variables.scss';

.workflow-order-number {
  align-items: center;
  background: white;
  border-radius: 3px;
  border: solid 3px black;
  color: black;
  display: inline-flex;
  min-height: 1.75em;
  justify-content: center;
  margin: 0 1rem;
  padding: 0.1em;
  position: relative;
  vertical-align: middle;
  min-width: 1.75em;
}

.skuOos-notes {
  text-align: left;
  font-size: 2.1rem;
}

.done-page--end {
  margin-top: 1rem;
}

.workflow-step__contactfsl {
  padding: 0;
}

.contactfsl-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactfsl-info-h1 {
  font-size: 3.5rem;
  margin-bottom: 0;
  margin-left: 15px;
  text-align: center;
}

.contactfsl-main {
  height: 120px;
  width: 100%;
  display: flex;
  background-color: #f0f0f5;
  align-items: center;
  justify-content: center;
}
.place-shipment-text {
  display: flex;
  padding-top: 50px;
  font-size: 1.5rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.contactfsl-text {
  font-size: 1.5rem;
}
.info-content-wsn {
  padding: 50px;
  width: 100%;
  text-align: center;
}

.dashboard-link {
  text-align: center;
  padding-top: 30px;
  font-size: 24px;
  color: $asurionPrimary;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.missing-wsn-go-back-link {
  display: flex;
  background-color: $asurionWarning;
  height: 85px;
  width: 100%;
  margin-top: -100px;
  align-items: center;
  justify-content: center;
}

.missing-wsn-link-text {
  font-size: 32px;
  margin-left: 15px;
}

.go-back {
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
}
