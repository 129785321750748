// workflow-footer styles

.workflow-footer {
  flex-flow: 0;
  height: 7.5rem;
  width: 100%;
  @media print {
    display: none;
  }
  @media screen and (max-width: 1367px) {
    height: 6.5rem;
  }
  @media screen and (max-width: 1281px) {
    height: 8.5rem;
  }
}

// workflow-progress-bar styles

.workflow-progress-bar {
  align-items: center;
  background: white;
  border-top: solid 1px #e1e1e1;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1367px) {
    height: 88px;
    margin-top: 13px;
  }
  @media screen and (max-width: 1281px) {
    height: 87px;
    margin-top: 37px;
  }
}

.worflow-progress-bar-no-margin {
  @media screen and (max-width: 1367px) {
    height: 87px;
    margin-top: -20px;
  }
  @media screen and (max-width: 1281px) {
    height: 87px;
    margin-top: 0px;
  }
}

.workflow-progress-bar__step-indicator {
  position: absolute;
  width: auto;
  transition: right 200ms 0ms;
  transition-timing-function: cubic-bezier(1, 0, 0.5, 1);
  z-index: 0;
}
