@import '../../../styles/variables';

.section-content {
  height: 100%;
  width: 100%;

  .section-header {
    display: flex;
    padding: 10px;
    align-content: center;
    justify-content: space-between;
    height: 65px;
    border-bottom: 2px solid $greyBackground;

    label {
      font-size: $text-giga;
      font-weight: 700;
      flex: 2;
    }
  }
}
