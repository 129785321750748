.sprint-border--left {
  border-left-color: $sprintColor !important;
}

.sprint-border--top {
  border-top-color: $sprintColor !important;
}

.sprint-background {
  background: $sprintColor !important;
}

.verizon-border--left {
  border-left-color: $verizonColor !important;
}

.verizon-border--top {
  border-top-color: $verizonColor !important;
}

.verizon-background {
  background: $verizonColor !important;
}

.att-border--left {
  border-left-color: $attColor !important;
}

.att-border--top {
  border-top-color: $attColor !important;
}

.att-background {
  background: $attColor !important;
}
