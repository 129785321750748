@import '../../../../../styles/variables';

.room-content__container {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid $greyBackground;

  .room-heading__container {
    display: flex;
    flex-direction: row;
    padding: 20px 10px;

    .room-heading__badge {
      height: 16px;
      width: 16px;
    }

    .room-heading__badge-new {
      border-radius: 50%;
      background: $badgeColor;
      margin: 5px 10px;
    }

    .room-heading__title {
      display: flex;
      flex-direction: column;
      font-size: $text-kilo;
      color: $subTextColor;

      label {
        font-size: $text-mega;
        font-weight: 700;
        color: black;
      }
    }
  }

  .room-body__container {
    display: flex;
    flex-direction: row;
    font-size: $text-kilo;
    padding: 10px;

    .room-body__content {
      flex: 2;
      padding-right: 10px;
      white-space: pre-wrap;
    }
  }
}
